import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as weStyles from '../styles/we.module.scss';
import 'swiper/swiper-bundle.min.css';
import { graphql, useStaticQuery } from 'gatsby';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const We = () => {
  const data = useStaticQuery(graphql`
    query {
      weImg1: file(relativePath: { eq: "we/we1.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      weImg2: file(relativePath: { eq: "we/we2.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      weImg3: file(relativePath: { eq: "we/we3.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const { weImg1, weImg2, weImg3 } = data;

  return (
    <>
      <Swiper
        className={weStyles.swiper}
        slidesPerView={1}
        threshold={20}
        navigation
        loop={true}
        speed={700}
        pagination={{ clickable: false }}
        // scrollbar={{ hide: false }}
        // onSlideChange={() => console.log('slide change')}
        // onClick={() => console.log('slide click')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <GatsbyImage
            image={weImg1.childImageSharp.gatsbyImageData}
            className={weStyles.weBackground}
            alt="we1"
          />
          <div className={weStyles.dimmed}></div>
          <div className={weStyles.slideContent}>
            <h3 className={weStyles.slideTitle}>
              인스앤은<br />콘텐츠마케팅<br />프로덕션입니다
            </h3>
            <span className={weStyles.slideSummary}>
              인스앤은 고객을 위한 최고의<br />콘텐츠를 제공합니다
            </span>
            <span className={weStyles.slideSummaryEng}>
              INSAND is the content marketing production that offers the best<br />content for clients.
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <GatsbyImage
            image={weImg2.childImageSharp.gatsbyImageData}
            className={weStyles.weBackground}
            alt="we2"
          />
          <div className={weStyles.dimmed}></div>
          <div className={weStyles.slideContent}>
            <h3 className={weStyles.slideTitle}>
              컨셉의 가치<br />경험의 가치<br />완벽의 가치
            </h3>
            <span className={weStyles.slideSummary}>
              인스앤은 가치를 위한<br />콘텐츠를 제공합니다
            </span>
            <span className={weStyles.slideSummaryEng}>
              We design differentiated Value of concept<br />We design the value of experience through the understanding of users<br />We design the value of perfection through repetitive revisions
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <GatsbyImage
            image={weImg3.childImageSharp.gatsbyImageData}
            className={weStyles.weBackground}
            alt="we3"
          />
          <div className={weStyles.dimmed}></div>
          <div className={weStyles.slideContent}>
            <h3 className={weStyles.slideTitle}>
              인스앤은<br />콘텐츠 마케팅의<br />모든 서비스 제공
            </h3>
            <span className={weStyles.slideSummary}>
              콘텐츠 마케팅 전략 수립과 실행<br />그리고 프로덕션까지
            </span>
            <span className={weStyles.slideSummaryEng}>
            Establishment and execution of content marketing strategies to their<br />production INSAND is the one-stop provider that
            </span>
          </div>   
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default We;
