import React from 'react';
import * as noticeStyles from '../styles/notice.module.scss';
import PlusIcon from '../images/common/ic_plus.png';

const Notice = ({ list }) => {
  return (
    <div className={noticeStyles.notice}> 
      {list.map((item) => ( 
        <div key={item.id} className={noticeStyles.noticeList}>
          <div dangerouslySetInnerHTML={{ __html: item.html }} className={noticeStyles.noticeText}/>
          <div className={noticeStyles.noticeDate}>{item.frontmatter.date}</div> 
        </div> 
      ))}
      <div className={noticeStyles.noticeMore}>
        <div className={noticeStyles.btnPlus}>
          <img src={PlusIcon} alt='more'/>
        </div>
        <div className={noticeStyles.moreText}>Look forward to more news.</div>
      </div>
    </div>
  );
};

export default Notice;