import React from 'react';
import * as style from '../styles/contact.module.scss';

import smile from '../images/common/ic_smile.png'

const Contact = () => {
  return (
    <div className={style.contact}>
      <h3 className={style.contactEmail}>
        ryu<br />@insand.co.kr
      </h3>
      <span className={style.contactInfo}>
        RYU PD<br />
        070-8680-7007<br />
        Head 경기도 고양시 일산동구 호수로 358-39 324호<br />
        Branch 서울시 종로구 삼봉로95, 대성스카이렉스 빌딩 401
      </span>
      <div className={style.contactSend}>
        <img src={smile} alt='smile' className={style.icon}/>
        <span className={style.address}>PLZ. <a href='mailto:ryu@insand.co.kr'>ryu@insand.co.kr</a></span> 
      </div>
      <span className={style.contactSummary}>INSAND is the content marketing production that offers the best content for clients.</span>
    </div>
  )
};

export default Contact;

