import * as React from 'react';
import * as style from '../styles/logo.module.scss';

import logo from '../images/logo_insand.svg';

const Logo = () => {
  return (
    <div className={style.logo}>
      <img src={logo} alt="INSAND"/>
      <span className={style.logoText}>ⓒ Since 2000</span> 
    </div>
  )
}

export default Logo;