import React from 'react';
import ReactPlayer from 'react-player'
import * as backgroundVideoStyles from '../styles/background-video.module.scss';

const BackgroundVideo = ({ url, main, isAudioOn, playing }) => (
  <div className={`${backgroundVideoStyles.videoBackground} ${main ? backgroundVideoStyles.videoBackgroundMain: ''}`}>
    <ReactPlayer
      className={backgroundVideoStyles.videoForeground}
      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      url={url}
      playing={playing}
      muted={!isAudioOn}
      loop={true}
      controls={false}
      width={'auto'}
      height={'100%'} 
      playsinline
    />
  </div>
);
export default BackgroundVideo;
