import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  // Autoplay,
  Scrollbar,
} from 'swiper/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as careerStyles from '../styles/career.module.scss';
import 'swiper/swiper-bundle.min.css';
import { Link } from 'gatsby';
import LinkIcon from '../images/common/ic_feather_arrow.png'

// SwiperCore.use([Navigation, Pagination, Autoplay, Scrollbar]);
SwiperCore.use([Navigation, Pagination, Scrollbar]);

const Career = ({ list }) => {
  return (
    <>
      <Swiper
        className={careerStyles.swiper}
        slidesPerView={1}
        threshold={20}
        navigation
        loop={true}
        speed={700}
        pagination={{ clickable: false }}
        // scrollbar={{ hide: false }}
        // spaceBetween={50} 
        // onSlideChange={() => console.log('slide change')}
        // onClick={() => console.log('slide click')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {list.map((item) => (
          <SwiperSlide key={item.id}>
            <GatsbyImage
              image={item.frontmatter.backgroundImg.childImageSharp.gatsbyImageData}
              className={careerStyles.careerBackground}
              alt={item.frontmatter.title}
            />
            <div className={careerStyles.dimmed}></div>
            <div className={careerStyles.slideContent}>
              <h3 className={careerStyles.slideTitle} dangerouslySetInnerHTML={{ __html: item.html }}></h3>
              <span className={careerStyles.slideSummary}>{item.frontmatter.subject1}</span> 
              {
                item.frontmatter.type !== 'none' &&
                <Link
                  to={`/portfolio/${item.frontmatter.name}`}
                  state={{modal: true}}
                  className={careerStyles.slideLink}
                >
                  <img src={LinkIcon} alt='open Link'/>
                </Link>
              }
              <span className={careerStyles.slideSummaryEng}>{item.frontmatter.subject2}</span> 
            </div> 
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Career;
