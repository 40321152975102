import * as React from 'react';
import * as style from '../styles/floating-button.module.scss';
import scrollTo from 'gatsby-plugin-smoothscroll';

import arrow from '../images/common/ic_arrow.png';

const FloatingButton = ({text = 'TOP', location = 'top', skip}) => (
  <button className={`${style.floatingButton} ${skip ? style.floatingButtonSkip: ''}`} onClick={() => scrollTo(`#${location}`)}>
    <img src={arrow} alt="arrow" />
    <span>{text}</span>
  </button>
);
export default FloatingButton;