import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby'; 
import { Helmet } from "react-helmet"
import scrollTo from 'gatsby-plugin-smoothscroll';

import '../styles/global.scss';
import * as style from '../styles/layout.module.scss';
import BackgroundVideo from '../components/background-video';
import We from '../components/we';
import Career from '../components/career';
import Contact from '../components/contact';
import Notice from '../components/notice';
import Logo from '../components/logo';  
import FloatingButton from '../components/floating-button';
import AudioOnIcon from '../images/common/ic_audio_on.png';
import AudioOffIcon from '../images/common/ic_audio_off.png';

// markup
const IndexPage = ({ data }) => {
  const portfolio = data.portfolio.nodes;
  const notices = data.notice.nodes;

  const weRef = useRef(null)
  const careerRef = useRef(null)
  const contactRef = useRef(null)
  const newsRef = useRef(null)

  const [isTop, setIsTop] = useState(true);
  const [isAudioOn, setIsAudioOn] = useState(false);

  useEffect(() => {
    let observer
    if (weRef.current && careerRef.current && contactRef.current && newsRef.current) {
      const options = {
        threshold: 0.5,
      }
      observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const navElement = document.querySelector(
            `button[href="/#${entry.target.id}"]`,
          )
          if (entry.isIntersecting) {
            if (!navElement.classList.contains(style.active)) {
              navElement.classList.add(style.active)
            }
          } else if (navElement.classList.contains(style.active)) {
            navElement.classList.remove(style.active)
          }
        })
      }, options)
      observer.observe(weRef.current)
      observer.observe(careerRef.current)
      observer.observe(contactRef.current)
      observer.observe(newsRef.current)
    }
    return () => observer.disconnect()
  }, [weRef, careerRef, contactRef, newsRef])

  useEffect(() => {
    const topElement = document.getElementById('top');
    const io = new IntersectionObserver(entries => {
      entries.forEach(entry => setIsTop(entry.isIntersecting))
    }, {threshold: 0.5})
    io.observe(topElement)

    return () => io.disconnect()
  }, []);

  return (
    <div className={style.main}>
      <Helmet>
        <title>INSAND</title>
        <meta name="description" content="Inspiration and..."/>
        <meta name="keywords" content="INSAND,인스앤,영상,영상제작"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="인스앤"/>
        <meta property="og:description" content="Inspiration and..."/>
        <meta property="og:image" content="https://insand.co.kr/logo.png"/>
        <meta property="og:url" content="https://insand.co.kr/"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="인스앤"/>
        <meta name="twitter:url" content="https://insand.co.kr/"/>
        <meta name="twitter:image" content="https://insand.co.kr/logo.png"/>
        <meta name="twitter:description" content="Inspiration and..."/>
      </Helmet>
      <div className={style.scrollLayout}>
        <nav className={style.nav}>
          <button onClick={() => scrollTo('#we')} href="/#we">WE</button>
          <button onClick={() => scrollTo('#career')} href="/#career">CAREER</button>
          <button onClick={() => scrollTo('#contact')} href="/#contact">CONTACT</button>
          <button onClick={() => scrollTo('#news')} href="/#news">NEWS</button>
          <a href="https://ejemtour.co.kr" target="_blank" rel="noreferrer">TOUR</a>
        </nav>
        {/* Main banner */}
        <div className={style.layout} id="top"> 
          <Logo />
          <BackgroundVideo
            url="https://insand-home.s3.ap-northeast-2.amazonaws.com/upload/insand_intro/2022_intro.m3u8"
            main
            isAudioOn={isAudioOn}
            playing={isTop}
          />
        </div>
        {/* We */}
        <div className={style.layout} id="we" ref={weRef} > 
          <Logo />
          <h2 className={style.layoutTitle}>We</h2>
          <We />
        </div>
        {/* Career */}
        <div className={style.layout} id="career" ref={careerRef} >
          <Logo />
          <h2 className={style.layoutTitle}>Career</h2>
          <Career list={portfolio} />
        </div>
        {/* Contact */} 
        <div className={`${style.layout} ${style.layoutContact}`} id="contact" ref={contactRef} >
          <Logo />
          <h2 className={style.layoutTitle}>Contact</h2>
          <Contact />
        </div> 
        <div className={`${style.layout} ${style.layoutNews}`} id="news" ref={newsRef} >
          <Logo />
          <h2 className={style.layoutTitle}>News</h2>
          <Notice list={notices} /> 
        </div>
        {isTop && <button onClick={()=>setIsAudioOn(!isAudioOn)} className={style.iconAudio}><img src={isAudioOn ? AudioOnIcon : AudioOffIcon} alt='icon'/></button>}
        {isTop?<FloatingButton text="SKIP" location="we" skip/>:<FloatingButton />}
      </div>
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query CareerList {
    portfolio: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: ASC }
      filter: { fileAbsolutePath: { regex: "/(portfolio)/" } }
    ) {
      nodes {
        id
        html
        frontmatter {
          type
          name
          title
          subject1
          subject2
          backgroundImg {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    notice: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/(notice)/" } }
    ) {
      nodes {
        id
        html
        frontmatter {
          date
        }
      }
    }
  }
`;
